<script setup lang="ts">
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import Button from 'primevue/button';
import { LoadingSpinner, Logo } from '@t16t/shared/components';
import { LogtoClientError } from '@logto/capacitor';
import { sleep } from '@t16t/shared/utils';
import { definePageMeta } from '#imports';
import { useApiClient, useAuthManager } from '~/auth-module';
import { useErrorToast } from '~/composables/useErrorToast';
import FullScreenLoadingSpinner from '~/components/FullScreenLoadingSpinner.vue';

definePageMeta({
    name: 'Auth',
    layout: 'auth'
});

const { authManager } = useAuthManager();
const { setupApiClient } = useApiClient();
const router = useRouter();
const loading = ref(false);
const showErrorToast = useErrorToast();

async function handleSignIn() {
    try {
        loading.value = true
        await authManager.signIn();
        const token = await authManager.accessToken();
        if(authManager.getDefaultDriver() !== 'none') {
            await setupApiClient(token);
        }

        return 'success';
    } catch(e: any) {
        if(!(e instanceof LogtoClientError)) {
            console.log(e)
            showErrorToast('Operation failed', 'There has been an issue while trying to authenticate, please try again');
            loading.value = false
            return 'failure'
        }

        console.log(e)

        return 'retry';
    }
}
async function onClick() {
    let result = await handleSignIn();
    await sleep(1500);
    if(result === 'retry') {
        result = await handleSignIn();
    }

    if(result === 'failure') {
        return;
    }

    setTimeout(() => {
        router.replace({name: 'History'})
        loading.value = false;
    }, 1500)
}
</script>

<template>
    <FullScreenLoadingSpinner :visible="loading"></FullScreenLoadingSpinner>
    <div class="max-w-screen-lg h-screen mx-auto flex flex-col justify-center overflow-y-hidden md:p-20">
        <div class="bg-surface-0 h-full md:h-1/2 lg:h-3/4 flex flex-col justify-center md:rounded-lg">
            <div class="h-full flex flex-col items-center justify-between gap-4 p-8 md:p-8 md:py-8 overflow-y-hidden">  
                <Logo
                    :variant="'icon'"
                    class="size-24 self-end"
                />
                <Button
                    @click="() => onClick()"
                    label="Get started"
                    :size="'large'"
                    class="w-full !text-lg"
                    :loading="loading"
                >
                    <template #loadingicon>
                        <LoadingSpinner class="size-4" />
                    </template>
                </Button>
            </div>
        </div>
    </div>
</template>